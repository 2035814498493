@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900);
i {
  font-family: 'line-awesome'; }

body {
  font-family: "Roboto"; }

.buyer-schedules .table-actions {
  display: grid;
  grid-template-columns: 30% 30%;
  grid-column-gap: 10%;
  justify-content: center; }

.buyers {
  display: flex;
  flex-wrap: wrap; }
  .buyers .buyer {
    min-width: calc((100%/2) - 15px);
    margin-top: 1rem;
    margin-right: 15px; }
    .buyers .buyer .card-header {
      background: white; }
      .buyers .buyer .card-header h4 {
        font-size: 1rem; }
    .buyers .buyer .list {
      list-style: none; }

.list-group.line-items .line-item {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  justify-content: center; }

.campaigns {
  padding-top: 5em; }

.campaign .breadcrumb {
  border-radius: 0;
  background: white;
  padding-left: 1rem;
  z-index: 100;
  position: absolute;
  border-bottom: 1px solid #e6eaee; }

.campaign .card {
  margin-top: 1rem; }

.campaign .buyer-campaigns {
  max-width: 10rem; }

.campaign .buyer-campaigns .badge-primary {
  background: rgba(0, 4, 23, 0.67); }

.red {
  color: red; }

.green {
  color: green; }

.creative-span {
  margin-right: 10px;
  margin-top: 10px;
  background: rgba(0, 4, 23, 0.67);
  font-family: 'Roboto';
  font-size: 1rem;
  font-weight: 300;
  border-radius: 0; }

.forecast-setup-form {
  display: grid;
  grid-template-areas: "settings schedule schedule" "buyer-setup buyer-setup buyer-setup";
  grid-template-columns: 1.5fr 2fr;
  grid-template-rows: 400px 1fr;
  grid-gap: 1rem; }
  .forecast-setup-form .settings {
    grid-area: settings; }
    .forecast-setup-form .settings .heading {
      font-size: 1.2em;
      font-weight: 700;
      text-align: center; }
    .forecast-setup-form .settings .form {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 10px;
      padding: 1.3em;
      justify-content: center;
      align-content: center;
      text-align: center; }
      .forecast-setup-form .settings .form .form-group {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1px;
        justify-items: center;
        align-items: center;
        width: 100%;
        border-top: 1px solid rgba(0, 0, 0, 0.125);
        padding-top: 1em; }
        .forecast-setup-form .settings .form .form-group .time {
          display: grid;
          grid-template-rows: 1fr 1fr; }
  .forecast-setup-form .schedule {
    grid-area: schedule;
    text-align: center;
    overflow-y: scroll;
    border-radius: 20px;
    border: 1px solid #d6d6d6;
    padding: 1em;
    padding-bottom: 2rem;
    padding-top: 2rem; }
  .forecast-setup-form .buyer-setup {
    grid-area: buyer-setup; }
    .forecast-setup-form .buyer-setup #buyer-campaigns {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px; }
    .forecast-setup-form .buyer-setup .list-group .list-group-item {
      display: grid;
      grid-template-columns: 1fr 40px; }
      .forecast-setup-form .buyer-setup .list-group .list-group-item span {
        font-size: 1em;
        font-weight: 200; }

.table-wrapper{
    /* max-height:500px; */
    /* overflow-y:scroll; */
    border:1px solid whitesmoke;
}

.report-parameters {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  align-items: center;
  box-shadow: 0 6px 15px rgba(36, 37, 38, 0.08);
  border-radius: 10px;
  margin-bottom: 3rem;
  background: #FeFeFeFe;
  padding: 1rem 0 1rem 0;
  justify-content: space-between;
  align-items: space-between; }
  .report-parameters .header {
    font-weight: 200;
    text-align: center;
    font-size: 1.2rem; }
  .report-parameters .fields {
    display: grid;
    float: right;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    justify-content: center;
    width: 100%;
    grid-gap: 0.3rem;
    padding: 0 1rem 0 1rem;
    align-items: center; }
    .report-parameters .fields .btn {
      margin-top: 2rem;
      text-align: center; }
    .report-parameters .fields .react-datepicker-wrapper {
      min-width: 100%; }
    .report-parameters .fields.vertical {
      grid-template-columns: 0.8fr;
      justify-content: center; }

.percentagelift {
  position: relative;
  width: 100%;
  height: 100%;
  background: #dadada;
  border-radius: 2px;
  display: grid;
  grid-template-columns: 1fr 1fr; }
  .percentagelift .percentagelift-left {
    border-right: 2px dotted rgba(0, 0, 0, 0.687); }
  .percentagelift .percentagelift-value {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto; }

.report-card {
  box-shadow: 0 6px 15px rgba(36, 37, 38, 0.08);
  border: none; }
  .report-card .rt-thead.-headerGroups {
    background: #434343;
    color: white; }
    .report-card .rt-thead.-headerGroups .rt-th {
      background: #434343;
      border-right: 1px solid white; }
  .report-card .card-header {
    background: white;
    border-bottom: 1px solid whitesmoke;
    display: grid;
    grid-template-columns: 1fr 0.2fr;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px; }
    .report-card .card-header h5 {
      font-weight: 200; }
    .report-card .card-header .actions {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(10px, 60px));
      align-items: end;
      border-radius: 10px;
      justify-content: end;
      align-content: end;
      float: right; }
      .report-card .card-header .actions .btn {
        border-radius: 10px;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        border: none;
        background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
        color: black; }
        .report-card .card-header .actions .btn i {
          color: black; }
      .report-card .card-header .actions :hover {
        text-decoration: none;
        color: black;
        box-shadow: 0 6px 15px rgba(36, 37, 38, 0.08);
        background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%); }
        .report-card .card-header .actions :hover i {
          color: black; }

.custom-select {
  width: 100%;
  padding: 0;
  height: auto; }
  .custom-select .custom-select__control {
    min-width: 100%;
    display: grid;
    grid-template-columns: 1fr 0.1fr; }

.total {
  border-radius: 10px;
  margin: 0 auto;
  width: 100%;
  position: relative;
  padding: 0 0 1rem 0;
  margin: 1rem 0 1rem 0; }
  .total div {
    position: relative; }
  .total .total-by-header {
    text-align: left;
    width: 100%;
    margin: 0 auto;
    font-weight: 200;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    padding-left: 1rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; }
  .total .total-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    justify-content: center;
    grid-gap: 15px; }
  .total .total-card {
    background: #ffffff;
    box-shadow: 0 6px 15px rgba(36, 37, 38, 0.08);
    border-radius: 10px;
    text-align: center;
    justify-content: center;
    margin-bottom: 20px;
    margin: 0 0.5rem 0 0.5rem; }
    .total .total-card .total-card-heading {
      background: #002e2e;
      width: 100%;
      padding: 1rem 0 1rem 0;
      margin-bottom: 2rem;
      text-align: center;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px; }
      .total .total-card .total-card-heading h2 {
        color: white;
        font-size: 1em !important;
        font-weight: 200; }
    .total .total-card .total-card-body {
      display: grid;
      grid-gap: 1em;
      margin-bottom: 1rem; }
      .total .total-card .total-card-body span {
        font-size: 1em;
        padding-bottom: 0.5rem; }

.loader {
  position: absolute;
  z-index: 10000; }
  .loader .loader-wrapper {
    width: 100%;
    height: 100vh;
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: center;
    position: relative;
    margin: 0 auto; }

.lds-ellipsis {
  position: relative;
  width: 64px;
  height: 64px; }

.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: black;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0); }

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
          animation: lds-ellipsis1 0.6s infinite; }

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite; }

.lds-ellipsis div:nth-child(3) {
  left: 26px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite; }

.lds-ellipsis div:nth-child(4) {
  left: 45px;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
          animation: lds-ellipsis3 0.6s infinite; }

@-webkit-keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0); } }

@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0); } }

@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(19px, 0);
            transform: translate(19px, 0); } }

@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(19px, 0);
            transform: translate(19px, 0); } }

.login-wrapper {
  display: grid;
  grid-template-columns: 1.2fr 1fr 2.2fr;
  align-content: center;
  align-items: center; }
  .login-wrapper .login {
    grid-column: 2/ 3;
    box-shadow: 0 6px 15px rgba(36, 37, 38, 0.08);
    border-radius: 10px;
    margin-bottom: 3rem;
    background: #FeFeFeFe;
    padding: 1rem 0 1rem 0;
    justify-content: space-between;
    align-items: space-between; }

.forecast-container {
  display: grid;
  grid-template-columns: 1.2fr 1.2fr 1.2fr;
  grid-gap: 1em;
  gap: 1em; }
  .forecast-container .forecast-card {
    box-shadow: 0 6px 15px rgba(36, 37, 38, 0.08);
    background-color: #fff;
    border-radius: 1em;
    display: grid;
    grid-template-columns: 1fr;
    margin: 0.3fr; }
    .forecast-container .forecast-card .card-header {
      display: block;
      background: none;
      text-align: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      background-image: linear-gradient(109.6deg, #141e30 11.2%, #243b55 91.1%);
      color: #fefefe; }
      .forecast-container .forecast-card .card-header .forecast-stats {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 200px));
        padding: 4% 0 4% 0;
        list-style: none; }
      .forecast-container .forecast-card .card-header h2 {
        font-size: 1.3rem;
        padding: 3% 0 3% 0;
        font-weight: 300;
        border-bottom: 1px solid #fefefe; }
    .forecast-container .forecast-card .forecast-grid {
      font-size: 0.8rem;
      min-height: 350px; }
      .forecast-container .forecast-card .forecast-grid .forecast-row {
        display: grid;
        grid-template-columns: 70px 40px repeat(auto-fill, minmax(50px, 1fr));
        grid-column-gap: 2px;
        -webkit-column-gap: 2px;
                column-gap: 2px;
        text-align: center;
        border-bottom: 1px solid #dfdfdf;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        overflow-x: scroll; }
        .forecast-container .forecast-card .forecast-grid .forecast-row .percentagelift {
          max-width: 40px;
          color: #000;
          font-weight: 800;
          background: rgba(255, 255, 255, 0.2); }
          .forecast-container .forecast-card .forecast-grid .forecast-row .percentagelift .percentagelift-left {
            opacity: 0.5; }
          .forecast-container .forecast-card .forecast-grid .forecast-row .percentagelift .percentagelift-right {
            opacity: 0.5; }
      .forecast-container .forecast-card .forecast-grid .forecast-grid-body {
        padding: 0;
        overflow-y: scroll;
        overflow-x: scroll;
        max-height: 350px;
        list-style: none; }

.forecast-chart {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1em;
  box-shadow: 0 6px 15px rgba(36, 37, 38, 0.08);
  background-color: #fff;
  border-radius: 1em;
  display: grid;
  margin: 0.3fr;
  padding: 1em; }

.call-center-stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr); }
  .call-center-stats .call-center-stat {
    text-align: center;
    margin: 0 10px 0 10px;
    background: rgba(0, 0, 0, 0.07);
    max-height: 600px;
    box-shadow: 0 6px 15px rgba(36, 37, 38, 0.08);
    border-radius: 1em; }
    .call-center-stats .call-center-stat .heading {
      background-image: linear-gradient(109.6deg, #141e30 11.2%, #243b55 91.1%);
      color: white;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
      .call-center-stats .call-center-stat .heading h1 {
        border-bottom: 1px solid #fefefe; }
    .call-center-stats .call-center-stat .highlight {
      background-color: #abe9cd;
      background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
      color: black; }
    .call-center-stats .call-center-stat .stat-body {
      max-height: 500px;
      overflow-y: scroll;
      line-height: 1.2em; }
      .call-center-stats .call-center-stat .stat-body .call-center-stat-inside .stat {
        padding-top: 5px;
        display: grid;
        grid-template-columns: 1fr 100px 10px;
        border-bottom: 1px solid #00000014; }
        .call-center-stats .call-center-stat .stat-body .call-center-stat-inside .stat h2 {
          text-align: left;
          line-height: 1.5em; }
        .call-center-stats .call-center-stat .stat-body .call-center-stat-inside .stat span {
          color: white;
          font-weight: 600;
          background-image: linear-gradient(109.6deg, #141e30 11.2%, #243b55 91.1%);
          box-shadow: 0 6px 15px rgba(36, 37, 38, 0.08);
          line-height: 2em;
          border-radius: 4px;
          font-size: 1.8em;
          margin-bottom: 5px; }

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  .call-center-stats {
    grid-template-columns: 1fr; } }

/* iPhone 6+, 7+, 8+ ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
  .call-center-stats {
    grid-template-columns: 1fr; } }

@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
  .call-center-stats {
    grid-template-columns: 1fr; } }

/* iPhone X ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
  .call-center-stats {
    grid-template-columns: 1fr; } }

@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
  .call-center-stats {
    grid-template-columns: 1fr; } }

/* iPhone XS Max, XR ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */ }

@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
  .call-center-stats {
    grid-template-columns: 1fr; } }

.wrapper {
  max-width: 100%;
  min-width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  display: grid;
  grid-gap: 0;
  grid-template-areas: "nav" "content" "footer";
  background: #f6f6f6; }

.main-head {
  grid-area: header;
  max-height: 80px;
  background: #FFFFFF;
  /* box-shadow: 2px -1px 5px 1px #676767; */
  border-bottom: 1px solid #cdcdcd3b;
  z-index: 1000; }

.content {
  /* padding-top:1rem; */
  grid-area: content;
  overflow-y: scroll;
  /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
  min-height: 100vh;
  max-height: 100vh;
  background-color: #f6f6f6;
  /* width:100%; */
  padding: 2rem;
  max-width: 100%; }

.main-nav {
  grid-area: nav;
  background: rgba(0, 4, 23, 0.67);
  min-height: 100px;
  background-image: linear-gradient(60deg, #29323c 0%, #485563 100%); }
  .main-nav .sidebar-heading * {
    font-weight: 200 !important;
    font-family: 'Roboto'; }

.main-footer {
  grid-area: footer; }

.breadcrumb {
  max-width: 100%;
  position: relative;
  margin: 0 auto;
  list-style: none; }

.breadcrumb ol {
  width: 100%; }

@media (min-width: 500px) {
  .wrapper {
    grid-template-columns: 1fr;
    grid-template-areas: "nav" "content" "ad"; }
  nav ul {
    display: flex;
    justify-content: space-between; } }

@media (min-width: 700px) {
  .wrapper {
    grid-template-columns: 1.5fr 11fr;
    grid-template-areas: "nav header" "nav content"; }
    .wrapper .main-nav {
      grid-area: nav;
      background: rgba(0, 4, 23, 0.67);
      min-height: 20vh;
      background-image: linear-gradient(60deg, #29323c 0%, #485563 100%); }
      .wrapper .main-nav .sidebar-heading * {
        font-weight: 200 !important;
        font-family: 'Roboto'; }
  nav ul {
    flex-direction: column; } }

.sidebar-heading {
  margin-top: 10px;
  color: #FEFEFE;
  border-bottom: 1px solid #cdcdcd3b;
  padding-bottom: 10px; }

.sidebar-heading > * {
  font-weight: 300; }

.sidebar-nav {
  display: block;
  font-weight: 300;
  margin-top: 1rem; }
  .sidebar-nav .sidebar-link {
    display: grid;
    grid-template-columns: 1fr; }
    .sidebar-nav .sidebar-link .sidebar-section {
      display: grid;
      grid-template-columns: 1fr 0.3fr;
      padding: 10px; }
      .sidebar-nav .sidebar-link .sidebar-section a {
        color: white; }
      .sidebar-nav .sidebar-link .sidebar-section :hover {
        text-decoration: none; }
      .sidebar-nav .sidebar-link .sidebar-section button {
        background: none;
        border: 0px;
        color: white; }
      .sidebar-nav .sidebar-link .sidebar-section :hover {
        color: white;
        background: #33333b; }
    .sidebar-nav .sidebar-link :hover {
      background: #33333b; }

.sidebar-nav::before {
  content: "\f131";
  display: flex;
  flex-direction: row-reverse;
  justify-content: end;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 2rem;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: white;
  padding-bottom: 15px;
  align-items: center; }

.sidebar-links {
  display: none;
  list-style: none;
  margin-left: 0;
  padding: 0; }
  .sidebar-links.show {
    display: block; }

.sidebar-links li {
  padding: 1rem 0 1rem 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem; }

.sidebar-links li.active {
  /* background:#cdcdcdb8; */
  background: #33333b; }

.sidebar-links li a {
  color: whitesmoke;
  width: 100%; }

.sidebar-links li:hover {
  background: #33333b; }

.sidebar-links li a:hover {
  text-decoration: none; }

@media (min-width: 768px) {
  .sidebar-heading {
    margin-top: 10px;
    color: #FEFEFE;
    border-bottom: 1px solid #cdcdcd3b;
    padding-bottom: 10px; }
  .sidebar-nav::before {
    display: none; }
  .sidebar-heading > * {
    font-weight: 300; }
  .sidebar-nav {
    display: block;
    font-weight: 300;
    margin-top: 1rem; }
  .sidebar-links {
    display: block;
    list-style: none;
    margin-left: 0;
    padding: 0; }
  .sidebar-links li {
    padding: 1rem 0 1rem 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .sidebar-links li.active {
    /* background:#cdcdcdb8; */
    background: #33333b; }
  .sidebar-links li a {
    color: whitesmoke;
    width: 100%; }
  .sidebar-links li:hover {
    background: #33333b; }
  .sidebar-links li a:hover {
    text-decoration: none; } }

