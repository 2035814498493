.login-wrapper {
  display: grid;
  grid-template-columns: 1.2fr 1fr 2.2fr;
  align-content: center;
  align-items: center; }
  .login-wrapper .login {
    grid-column: 2/ 3;
    box-shadow: 0 6px 15px rgba(36, 37, 38, 0.08);
    border-radius: 10px;
    margin-bottom: 3rem;
    background: #FeFeFeFe;
    padding: 1rem 0 1rem 0;
    justify-content: space-between;
    align-items: space-between; }
