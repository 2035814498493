.forecast-setup-form {
  display: grid;
  grid-template-areas: "settings schedule schedule" "buyer-setup buyer-setup buyer-setup";
  grid-template-columns: 1.5fr 2fr;
  grid-template-rows: 400px 1fr;
  grid-gap: 1rem; }
  .forecast-setup-form .settings {
    grid-area: settings; }
    .forecast-setup-form .settings .heading {
      font-size: 1.2em;
      font-weight: 700;
      text-align: center; }
    .forecast-setup-form .settings .form {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 10px;
      padding: 1.3em;
      justify-content: center;
      align-content: center;
      text-align: center; }
      .forecast-setup-form .settings .form .form-group {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1px;
        justify-items: center;
        align-items: center;
        width: 100%;
        border-top: 1px solid rgba(0, 0, 0, 0.125);
        padding-top: 1em; }
        .forecast-setup-form .settings .form .form-group .time {
          display: grid;
          grid-template-rows: 1fr 1fr; }
  .forecast-setup-form .schedule {
    grid-area: schedule;
    text-align: center;
    overflow-y: scroll;
    border-radius: 20px;
    border: 1px solid #d6d6d6;
    padding: 1em;
    padding-bottom: 2rem;
    padding-top: 2rem; }
  .forecast-setup-form .buyer-setup {
    grid-area: buyer-setup; }
    .forecast-setup-form .buyer-setup #buyer-campaigns {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px; }
    .forecast-setup-form .buyer-setup .list-group .list-group-item {
      display: grid;
      grid-template-columns: 1fr 40px; }
      .forecast-setup-form .buyer-setup .list-group .list-group-item span {
        font-size: 1em;
        font-weight: 200; }
