.call-center-stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr); }
  .call-center-stats .call-center-stat {
    text-align: center;
    margin: 0 10px 0 10px;
    background: rgba(0, 0, 0, 0.07);
    max-height: 600px;
    box-shadow: 0 6px 15px rgba(36, 37, 38, 0.08);
    border-radius: 1em; }
    .call-center-stats .call-center-stat .heading {
      background-image: linear-gradient(109.6deg, #141e30 11.2%, #243b55 91.1%);
      color: white;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
      .call-center-stats .call-center-stat .heading h1 {
        border-bottom: 1px solid #fefefe; }
    .call-center-stats .call-center-stat .highlight {
      background-color: #abe9cd;
      background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
      color: black; }
    .call-center-stats .call-center-stat .stat-body {
      max-height: 500px;
      overflow-y: scroll;
      line-height: 1.2em; }
      .call-center-stats .call-center-stat .stat-body .call-center-stat-inside .stat {
        padding-top: 5px;
        display: grid;
        grid-template-columns: 1fr 100px 10px;
        border-bottom: 1px solid #00000014; }
        .call-center-stats .call-center-stat .stat-body .call-center-stat-inside .stat h2 {
          text-align: left;
          line-height: 1.5em; }
        .call-center-stats .call-center-stat .stat-body .call-center-stat-inside .stat span {
          color: white;
          font-weight: 600;
          background-image: linear-gradient(109.6deg, #141e30 11.2%, #243b55 91.1%);
          box-shadow: 0 6px 15px rgba(36, 37, 38, 0.08);
          line-height: 2em;
          border-radius: 4px;
          font-size: 1.8em;
          margin-bottom: 5px; }

@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  .call-center-stats {
    grid-template-columns: 1fr; } }

/* iPhone 6+, 7+, 8+ ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
  .call-center-stats {
    grid-template-columns: 1fr; } }

@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
  /* Styles */
  .call-center-stats {
    grid-template-columns: 1fr; } }

/* iPhone X ----------- */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
  .call-center-stats {
    grid-template-columns: 1fr; } }

@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
  .call-center-stats {
    grid-template-columns: 1fr; } }

/* iPhone XS Max, XR ----------- */
@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */ }

@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
  /* Styles */
  .call-center-stats {
    grid-template-columns: 1fr; } }
