.forecast-container {
  display: grid;
  grid-template-columns: 1.2fr 1.2fr 1.2fr;
  gap: 1em; }
  .forecast-container .forecast-card {
    box-shadow: 0 6px 15px rgba(36, 37, 38, 0.08);
    background-color: #fff;
    border-radius: 1em;
    display: grid;
    grid-template-columns: 1fr;
    margin: 0.3fr; }
    .forecast-container .forecast-card .card-header {
      display: block;
      background: none;
      text-align: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      background-image: linear-gradient(109.6deg, #141e30 11.2%, #243b55 91.1%);
      color: #fefefe; }
      .forecast-container .forecast-card .card-header .forecast-stats {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 200px));
        padding: 4% 0 4% 0;
        list-style: none; }
      .forecast-container .forecast-card .card-header h2 {
        font-size: 1.3rem;
        padding: 3% 0 3% 0;
        font-weight: 300;
        border-bottom: 1px solid #fefefe; }
    .forecast-container .forecast-card .forecast-grid {
      font-size: 0.8rem;
      min-height: 350px; }
      .forecast-container .forecast-card .forecast-grid .forecast-row {
        display: grid;
        grid-template-columns: 70px 40px repeat(auto-fill, minmax(50px, 1fr));
        column-gap: 2px;
        text-align: center;
        border-bottom: 1px solid #dfdfdf;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        overflow-x: scroll; }
        .forecast-container .forecast-card .forecast-grid .forecast-row .percentagelift {
          max-width: 40px;
          color: #000;
          font-weight: 800;
          background: rgba(255, 255, 255, 0.2); }
          .forecast-container .forecast-card .forecast-grid .forecast-row .percentagelift .percentagelift-left {
            opacity: 0.5; }
          .forecast-container .forecast-card .forecast-grid .forecast-row .percentagelift .percentagelift-right {
            opacity: 0.5; }
      .forecast-container .forecast-card .forecast-grid .forecast-grid-body {
        padding: 0;
        overflow-y: scroll;
        overflow-x: scroll;
        max-height: 350px;
        list-style: none; }

.forecast-chart {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1em;
  box-shadow: 0 6px 15px rgba(36, 37, 38, 0.08);
  background-color: #fff;
  border-radius: 1em;
  display: grid;
  margin: 0.3fr;
  padding: 1em; }
