.total {
  border-radius: 10px;
  margin: 0 auto;
  width: 100%;
  position: relative;
  padding: 0 0 1rem 0;
  margin: 1rem 0 1rem 0; }
  .total div {
    position: relative; }
  .total .total-by-header {
    text-align: left;
    width: 100%;
    margin: 0 auto;
    font-weight: 200;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    padding-left: 1rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; }
  .total .total-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    justify-content: center;
    grid-gap: 15px; }
  .total .total-card {
    background: #ffffff;
    box-shadow: 0 6px 15px rgba(36, 37, 38, 0.08);
    border-radius: 10px;
    text-align: center;
    justify-content: center;
    margin-bottom: 20px;
    margin: 0 0.5rem 0 0.5rem; }
    .total .total-card .total-card-heading {
      background: #002e2e;
      width: 100%;
      padding: 1rem 0 1rem 0;
      margin-bottom: 2rem;
      text-align: center;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px; }
      .total .total-card .total-card-heading h2 {
        color: white;
        font-size: 1em !important;
        font-weight: 200; }
    .total .total-card .total-card-body {
      display: grid;
      grid-gap: 1em;
      margin-bottom: 1rem; }
      .total .total-card .total-card-body span {
        font-size: 1em;
        padding-bottom: 0.5rem; }
