.report-parameters {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  align-items: center;
  box-shadow: 0 6px 15px rgba(36, 37, 38, 0.08);
  border-radius: 10px;
  margin-bottom: 3rem;
  background: #FeFeFeFe;
  padding: 1rem 0 1rem 0;
  justify-content: space-between;
  align-items: space-between; }
  .report-parameters .header {
    font-weight: 200;
    text-align: center;
    font-size: 1.2rem; }
  .report-parameters .fields {
    display: grid;
    float: right;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    justify-content: center;
    width: 100%;
    grid-gap: 0.3rem;
    padding: 0 1rem 0 1rem;
    align-items: center; }
    .report-parameters .fields .btn {
      margin-top: 2rem;
      text-align: center; }
    .report-parameters .fields .react-datepicker-wrapper {
      min-width: 100%; }
    .report-parameters .fields.vertical {
      grid-template-columns: 0.8fr;
      justify-content: center; }

.percentagelift {
  position: relative;
  width: 100%;
  height: 100%;
  background: #dadada;
  border-radius: 2px;
  display: grid;
  grid-template-columns: 1fr 1fr; }
  .percentagelift .percentagelift-left {
    border-right: 2px dotted rgba(0, 0, 0, 0.687); }
  .percentagelift .percentagelift-value {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto; }

.report-card {
  box-shadow: 0 6px 15px rgba(36, 37, 38, 0.08);
  border: none; }
  .report-card .rt-thead.-headerGroups {
    background: #434343;
    color: white; }
    .report-card .rt-thead.-headerGroups .rt-th {
      background: #434343;
      border-right: 1px solid white; }
  .report-card .card-header {
    background: white;
    border-bottom: 1px solid whitesmoke;
    display: grid;
    grid-template-columns: 1fr 0.2fr;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px; }
    .report-card .card-header h5 {
      font-weight: 200; }
    .report-card .card-header .actions {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(10px, 60px));
      align-items: end;
      border-radius: 10px;
      justify-content: end;
      align-content: end;
      float: right; }
      .report-card .card-header .actions .btn {
        border-radius: 10px;
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        border: none;
        background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
        color: black; }
        .report-card .card-header .actions .btn i {
          color: black; }
      .report-card .card-header .actions :hover {
        text-decoration: none;
        color: black;
        box-shadow: 0 6px 15px rgba(36, 37, 38, 0.08);
        background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%); }
        .report-card .card-header .actions :hover i {
          color: black; }

.custom-select {
  width: 100%;
  padding: 0;
  height: auto; }
  .custom-select .custom-select__control {
    min-width: 100%;
    display: grid;
    grid-template-columns: 1fr 0.1fr; }
