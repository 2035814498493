.buyer-schedules .table-actions {
  display: grid;
  grid-template-columns: 30% 30%;
  grid-column-gap: 10%;
  justify-content: center; }

.buyers {
  display: flex;
  flex-wrap: wrap; }
  .buyers .buyer {
    min-width: calc((100%/2) - 15px);
    margin-top: 1rem;
    margin-right: 15px; }
    .buyers .buyer .card-header {
      background: white; }
      .buyers .buyer .card-header h4 {
        font-size: 1rem; }
    .buyers .buyer .list {
      list-style: none; }

.list-group.line-items .line-item {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  justify-content: center; }

.campaigns {
  padding-top: 5em; }

.campaign .breadcrumb {
  border-radius: 0;
  background: white;
  padding-left: 1rem;
  z-index: 100;
  position: absolute;
  border-bottom: 1px solid #e6eaee; }

.campaign .card {
  margin-top: 1rem; }

.campaign .buyer-campaigns {
  max-width: 10rem; }

.campaign .buyer-campaigns .badge-primary {
  background: rgba(0, 4, 23, 0.67); }

.red {
  color: red; }

.green {
  color: green; }

.creative-span {
  margin-right: 10px;
  margin-top: 10px;
  background: rgba(0, 4, 23, 0.67);
  font-family: 'Roboto';
  font-size: 1rem;
  font-weight: 300;
  border-radius: 0; }
