.wrapper {
  max-width: 100%;
  min-width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  display: grid;
  grid-gap: 0;
  grid-template-areas: "nav" "content" "footer";
  background: #f6f6f6; }

.main-head {
  grid-area: header;
  max-height: 80px;
  background: #FFFFFF;
  /* box-shadow: 2px -1px 5px 1px #676767; */
  border-bottom: 1px solid #cdcdcd3b;
  z-index: 1000; }

.content {
  /* padding-top:1rem; */
  grid-area: content;
  overflow-y: scroll;
  /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
  min-height: 100vh;
  max-height: 100vh;
  background-color: #f6f6f6;
  /* width:100%; */
  padding: 2rem;
  max-width: 100%; }

.main-nav {
  grid-area: nav;
  background: rgba(0, 4, 23, 0.67);
  min-height: 100px;
  background-image: linear-gradient(60deg, #29323c 0%, #485563 100%); }
  .main-nav .sidebar-heading * {
    font-weight: 200 !important;
    font-family: 'Roboto'; }

.main-footer {
  grid-area: footer; }

.breadcrumb {
  max-width: 100%;
  position: relative;
  margin: 0 auto;
  list-style: none; }

.breadcrumb ol {
  width: 100%; }

@media (min-width: 500px) {
  .wrapper {
    grid-template-columns: 1fr;
    grid-template-areas: "nav" "content" "ad"; }
  nav ul {
    display: flex;
    justify-content: space-between; } }

@media (min-width: 700px) {
  .wrapper {
    grid-template-columns: 1.5fr 11fr;
    grid-template-areas: "nav header" "nav content"; }
    .wrapper .main-nav {
      grid-area: nav;
      background: rgba(0, 4, 23, 0.67);
      min-height: 20vh;
      background-image: linear-gradient(60deg, #29323c 0%, #485563 100%); }
      .wrapper .main-nav .sidebar-heading * {
        font-weight: 200 !important;
        font-family: 'Roboto'; }
  nav ul {
    flex-direction: column; } }
