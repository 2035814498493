.sidebar-heading {
  margin-top: 10px;
  color: #FEFEFE;
  border-bottom: 1px solid #cdcdcd3b;
  padding-bottom: 10px; }

.sidebar-heading > * {
  font-weight: 300; }

.sidebar-nav {
  display: block;
  font-weight: 300;
  margin-top: 1rem; }
  .sidebar-nav .sidebar-link {
    display: grid;
    grid-template-columns: 1fr; }
    .sidebar-nav .sidebar-link .sidebar-section {
      display: grid;
      grid-template-columns: 1fr 0.3fr;
      padding: 10px; }
      .sidebar-nav .sidebar-link .sidebar-section a {
        color: white; }
      .sidebar-nav .sidebar-link .sidebar-section :hover {
        text-decoration: none; }
      .sidebar-nav .sidebar-link .sidebar-section button {
        background: none;
        border: 0px;
        color: white; }
      .sidebar-nav .sidebar-link .sidebar-section :hover {
        color: white;
        background: #33333b; }
    .sidebar-nav .sidebar-link :hover {
      background: #33333b; }

.sidebar-nav::before {
  content: "\f131";
  display: flex;
  flex-direction: row-reverse;
  justify-content: end;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 2rem;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: white;
  padding-bottom: 15px;
  align-items: center; }

.sidebar-links {
  display: none;
  list-style: none;
  margin-left: 0;
  padding: 0; }
  .sidebar-links.show {
    display: block; }

.sidebar-links li {
  padding: 1rem 0 1rem 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem; }

.sidebar-links li.active {
  /* background:#cdcdcdb8; */
  background: #33333b; }

.sidebar-links li a {
  color: whitesmoke;
  width: 100%; }

.sidebar-links li:hover {
  background: #33333b; }

.sidebar-links li a:hover {
  text-decoration: none; }

@media (min-width: 768px) {
  .sidebar-heading {
    margin-top: 10px;
    color: #FEFEFE;
    border-bottom: 1px solid #cdcdcd3b;
    padding-bottom: 10px; }
  .sidebar-nav::before {
    display: none; }
  .sidebar-heading > * {
    font-weight: 300; }
  .sidebar-nav {
    display: block;
    font-weight: 300;
    margin-top: 1rem; }
  .sidebar-links {
    display: block;
    list-style: none;
    margin-left: 0;
    padding: 0; }
  .sidebar-links li {
    padding: 1rem 0 1rem 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .sidebar-links li.active {
    /* background:#cdcdcdb8; */
    background: #33333b; }
  .sidebar-links li a {
    color: whitesmoke;
    width: 100%; }
  .sidebar-links li:hover {
    background: #33333b; }
  .sidebar-links li a:hover {
    text-decoration: none; } }
